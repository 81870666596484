import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const Listing = ({ pageData }) => {
  const { t, lang } = useLocale()

  return (
    <section className="install-listing">
      <div className="container-fluid">
        <motion.h2 {...fadeAnimation}>
          {pageData?.acfInstall?.specialistsHeader}
        </motion.h2>
        <motion.div className="install-listing__table" {...fadeAnimation}>
          <div className="install-listing__table-header">
            <div>
              <strong>{t("Firma")}</strong>
            </div>
            <div>
              <strong>{t("Obszar realizacji")}</strong>
            </div>
            <div>
              <strong>{t("Numer telefonu")}</strong>
            </div>
            <div>
              <strong>{t("Adres email")}</strong>
            </div>
          </div>
          {pageData?.acfInstall?.specialistsContent?.map((item, index) => (
            <div className="install-listing__table-item" key={index}>
              <div>
                <address
                  dangerouslySetInnerHTML={{ __html: item?.company }}
                ></address>
              </div>
              <div>
                <p>
                  {t(
                    lang === "en" && item?.region.includes("woj.")
                      ? `voivodeship: ${item?.region.replace("woj.", "")}`
                      : item?.region
                  )}
                </p>
              </div>
              <div>
                <a href={`tel:${item?.phone}`}>{item?.phone}</a>
              </div>
              <div>
                <a href={`mailto:${item?.email}`}>{item?.email}</a>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}

export default Listing
